import { Inject, Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';
import { ArticleContentBlocks, ArticleImage } from '../../models/article.model';

@Injectable({
	providedIn: 'root'
})
export class ArticleContentBlockService {
	constructor(private formBuilder: FormBuilder, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {}

	static URLValidator = Validators.pattern('^(http(s):\\/\\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.,~#?&//=]*)$');
	static SlugValidator = Validators.pattern('^[a-z0-9]+(-[a-z0-9]+)*$');

	static atLeastOneRequired = (fields: Array<string>) => {
		return (group: FormGroup) => {
			for (const fieldName of fields) {
				if (group.get(fieldName).value && group.get(fieldName).value !== '') {
					return null;
				}
			}
			return { atLeastOneRequired: true };
		};
	};

	static trimValidator: ValidatorFn = (control: FormControl) => {
		if (control.value.startsWith(' ')) {
			return {
				'trimError': { value: 'control has leading whitespace' }
			};
		}
		if (control.value.endsWith(' ')) {
			return {
				'trimError': { value: 'control has trailing whitespace' }
			};
		}
		return null;
	};

	public mapContent(form: FormArray, content: ArticleContentBlocks[] = []) {
		form.clear({ emitEvent: false });
		(content || []).forEach((block: ArticleContentBlocks, index: number) => {
			form.push(this.createFormGroup(block));
		});
		// this.form.updateValueAndValidity({onlySelf: false, emitEvent: false});
	}

	public createFormGroup(block: ArticleContentBlocks | string): FormGroup {
		block = typeof block === 'string' ? ({ blockType: block } as ArticleContentBlocks) : block;
		switch (block.blockType) {
			case 'content_html':
				return this.formBuilder.group(
					{
						blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
						heading: this.formBuilder.control<string>(block.heading || '', []),
						html: this.formBuilder.control<string>(block.html || '', [])
					},
					{ validators: ArticleContentBlockService.atLeastOneRequired(['heading', 'html']) }
				);
			case 'content':
				return this.formBuilder.group(
					{
						blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
						heading: this.formBuilder.control<string>(block.heading || '', []),
						paragraph: this.formBuilder.control<string>(block.paragraph || '', [])
					},
					{ validators: ArticleContentBlockService.atLeastOneRequired(['heading', 'paragraph']) }
				);
			case 'image':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					image: this.createImageFormGroup(block.image || ({} as ArticleImage))
				});
			case 'video':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					vid: this.formBuilder.control<string>(block.vid || '', [Validators.required, ArticleContentBlockService.trimValidator]),
					description: this.formBuilder.control<string>(block.description || '', []),
					duration: this.formBuilder.control<number>(block.duration || 0, []),
					autoplay: this.formBuilder.control<boolean>(block.autoplay || false, []),
					image: this.createImageFormGroup(block.image || ({} as ArticleImage))
				});
			case 'live_match_id':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					liveMatchId: this.formBuilder.control<string>(block.liveMatchId || '', [Validators.required])
				});
			case 'monterosa':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					eventId: this.formBuilder.control<string>(block.eventId, [Validators.required]),
					projectId: this.formBuilder.control<string>(block.projectId ?? this.environment.monterosa.projectId, [Validators.required]),
					channelsEnabled: this.formBuilder.group({
						mobile: this.formBuilder.control<boolean>(block.channelsEnabled?.mobile ?? true, []),
						web: this.formBuilder.control<boolean>(block.channelsEnabled?.web ?? false, [])
					}),
					placeholder: this.formBuilder.group({
						target: this.formBuilder.control<string>(block.placeholder?.target ?? '', []),
						image: this.createImageFormGroup(block.placeholder?.image || ({} as ArticleImage), false)
					})
				});
			case 'apester':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					snippet: this.formBuilder.control<string>(block.snippet, [Validators.required]),
					url: this.formBuilder.control<string>(block.url, [Validators.required, ArticleContentBlockService.URLValidator])
				});
			case 'instagram':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					snippet: this.formBuilder.control<string>(block.snippet, [Validators.required]),
					url: this.formBuilder.control<string>(block.url, [Validators.required, ArticleContentBlockService.URLValidator])
				});
			case 'facebook':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					snippet: this.formBuilder.control<string>(block.snippet, [Validators.required]),
					url: this.formBuilder.control<string>(block.url, [Validators.required, ArticleContentBlockService.URLValidator])
				});
			case 'youtube':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					snippet: this.formBuilder.control<string>(block.snippet, [Validators.required]),
					url: this.formBuilder.control<string>(block.url, [Validators.required, ArticleContentBlockService.URLValidator])
				});
			case 'twitter':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					snippet: this.formBuilder.control<string>(block.snippet, [Validators.required]),
					url: this.formBuilder.control<string>(block.url, [Validators.required, ArticleContentBlockService.URLValidator])
				});
			case 'teaser':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					target: this.formBuilder.control<string>(block.target, [Validators.required]),
					relationship: this.formBuilder.control<string>(block.relationship, [Validators.required]),
					isPinned: this.formBuilder.control<boolean>(block.isPinned, []),
					hasVideo: this.formBuilder.control<boolean>(block.hasVideo, []),
					parameters: this.formBuilder.control<string>(block.parameters, [Validators.required]),
					text: this.formBuilder.control<string>(block.text, [Validators.required]),
					textShort: this.formBuilder.control<string>(block.textShort, [Validators.required]),
					headline: this.formBuilder.control<string>(block.headline, [Validators.required]),
					headlineShort: this.formBuilder.control<string>(block.headlineShort, [Validators.required]),
					image: this.createImageFormGroup(block.image || ({} as ArticleImage))
				});
			case 'single-line':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					heading: this.formBuilder.control<string>(block.heading || '', [])
				});
			case 'multi-line':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					paragraph: this.formBuilder.control<string>(block.paragraph || '', [])
				});
			case 'hidden':
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>(block.blockType, [Validators.required]),
					hiddenText: this.formBuilder.control<string>(block.hiddenText || '', [])
				});
			default:
				return this.formBuilder.group({
					blockType: this.formBuilder.control<string>('unknown', [Validators.required])
				});
		}
	}

	public createImageFormGroup(image?: ArticleImage, requireValidation = true): FormGroup {
		return this.formBuilder.group({
			desktop: this.formBuilder.group({ url: this.formBuilder.control<string>(image.desktop?.url || '', requireValidation ? [Validators.required, ArticleContentBlockService.URLValidator] : []) }),
			mobile: this.formBuilder.group({ url: this.formBuilder.control<string>(image.mobile?.url || '', requireValidation ? [Validators.required, ArticleContentBlockService.URLValidator] : []) }),
			portrait: this.formBuilder.group({ url: this.formBuilder.control<string>(image.portrait?.url || '', requireValidation ? [Validators.required, ArticleContentBlockService.URLValidator] : []) }),
			copyright: this.formBuilder.control<string>(image.copyright || '', requireValidation ? [Validators.required] : []),
			caption: this.formBuilder.control<string>(image.caption || '', []),
			alt: this.formBuilder.control<string>(image.alt || '', [])
		});
	}
}
