
    export const buildVars = {
      version: '1.8.0',
      build: '10054',
      angularVersion: '18.2.8',
	  nxVersion: '19.8.4',
      commitId: 'a6dda3480',
      commitTime: '2025-01-22 13:54:06 +0100',
	  branch: 'CON-monterosa'
    };
    