export interface BundesmasterEnvironment {
	readonly stage: 'development' | 'integration' | 'production';
	readonly domain: string;

	readonly jw: {
		readonly playlist: {
			readonly goals: {
				readonly dach: string;
				readonly nondach: string;
			};

			readonly player: string;
		};
	};

	readonly gmaps: {
		readonly key: string;
	};

	readonly monterosa: {
		readonly projectId: string;
	};

	readonly apis: {
		readonly assets: {
			readonly url: string;
		};

		readonly ai: {
			readonly url: string;
		};

		readonly stories: {
			readonly url: string;
		};

		readonly wapp: {
			readonly url: string;
			readonly key: string;
		};

		readonly content: {
			readonly url: string;
		};

		readonly proxy: {
			readonly url: string;
		};

		readonly playerCenter: {
			readonly url: string;
		};

		readonly appEvents: {
			readonly url: string;
		};
	};

	readonly videoLibrary: {
		readonly jwPlayerId: string;
	};
}
