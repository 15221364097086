import { BundesligaEmbed, BundesligaEmbedMonterosa, BundesligaEmbedSocials, EmbedPlatforms, Playtime } from '../bundesliga/common.model';
import { BundesligaMatchStates, MatchDateQuality, MatchHighlightVideo } from '../bundesliga/match.model';

export type LiveBlogEventAssociation = 'home' | 'away' | 'none';

export type BundesligaShotSavedTypes = 'shotOnGoalHeld' | 'penaltySaved';

export type BundesligaCardTypes = 'redCard' | 'yellowCard' | 'yellowRedCard';

export type BundesligaWhistleTypes = 'start_firstHalf' | 'end_firstHalf' | 'start_secondHalf' | 'end_secondHalf' | 'start_firstHalfExtra' | 'end_firstHalfExtra' | 'start_secondHalfExtra' | 'end_secondHalfExtra' | 'start_penalty' | 'end_penalty' | 'finalWhistle';

export type liveBlogEntryEditorialEmbedDetailEnum = EmbedPlatforms;

export type LivetickerSides = 'home' | 'away' | 'none';

/**
 *
 */
type LiveBlogEntryEditorialImageEnum = 'image';

/**
 * Common properties among all STS-Auto-Events.
 */
interface LiveBlogEntryCommon {
	eventId?: number | string;
	entryDate: string;
	matchSection: BundesligaMatchStates;
	side?: LiveBlogEventAssociation;
	playtime?: Playtime;
	order?: number;
	conference?: boolean;
	enableHtml?: boolean;
	hidden?: boolean;

	/**
	 * @deprecated former property for playtime
	 */
	minuteOfPlay?: Playtime;
}

/**
 * A generic/minimal person model for all kind of participants (e.g. player, coach).
 *
 * @todo/dfl should/could probably merged with models/bundesliga/match.MatchPlayer
 */
export interface LiveBlogPerson {
	dflDatalibraryObjectId: string;
	name: string;
	imageUrl: string;
}
export interface LiveBlogLineupPerson {
	dflDatalibraryObjectId: string;
	name: string;
	imageUrl: string;
	shirtNumber: number;
}

/**
 * Base Interface for all STS-Auto-Events.
 */
export interface LiveBlogAutoEventBase extends LiveBlogEntryCommon {
	detail: any;
	entryType: any;
	teams?: LiveBlogClubs;
}

/**
 *
 */
export interface LiveBlogEntryPlayerRecord extends LiveBlogAutoEventBase {
	entryType: 'playerRecord';

	detail: {
		headline?: string;
		text?: string;
		title: string;
		unit: string;
		value: number;
		person: LiveBlogPerson;
		matchFact: boolean;
	};
}

/**
 *
 */
export interface LiveBlogAutoEventGoal extends LiveBlogAutoEventBase {
	entryType: 'goal' | 'ownGoal';

	detail: {
		scorer: LiveBlogPerson;
		assists?: LiveBlogPerson[];
		score: {
			home: number;
			away: number;
		};
		penalty?: boolean;
		xG?: number;
		distanceToGoal?: number;
		shotSpeed?: number;
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		text?: string;
	};
}

/**
 *
 */
export interface LiveBlogAutoEventPenalty extends LiveBlogAutoEventBase {
	entryType: 'penalty';

	detail: {
		shooter?: LiveBlogPerson;
		score: {
			home: number;
			away: number;
		};
		saved?: boolean;
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		text?: string;
	};
}

/*
 */
export interface LiveBlogAutoEventVar extends LiveBlogAutoEventBase {
	entryType: 'videoAssistant';

	detail: {
		score?: {
			home: number;
			away: number;
		};
		situation?: string;
		review?: string;
		decision?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon
		 */
		text?: string;
	};
}

/**
 *
 */
export interface LiveBlogAutoEventWhistle extends LiveBlogAutoEventBase {
	entryType: BundesligaWhistleTypes;

	detail: {
		score?: {
			home: number;
			away: number;
		};
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		text?: string;
	};
}

/**
 *
 */
export interface LiveBlogAutoEventCard extends LiveBlogAutoEventBase {
	entryType: BundesligaCardTypes;

	detail: {
		person?: LiveBlogPerson;
		score?: {
			home: number;
			away: number;
		};
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		text?: string;
	};
}

/**
 *
 */
export interface LiveBlogAutoEventLineup extends LiveBlogAutoEventBase {
	entryType: 'lineup';

	detail: {
		lineup: LiveBlogLineupPerson[];
		score?: {
			home: number;
			away: number;
		};
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		text?: string;
	};
}

/**
 *
 */
export interface LiveBlogAutoEventSubstitution extends LiveBlogAutoEventBase {
	entryType: 'sub';
	detail: {
		in: LiveBlogPerson;
		out: LiveBlogPerson;
		score?: {
			home: number;
			away: number;
		};
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		text?: string;
	};
}

/**
 *
 */
export interface LiveBlogAutoEventShotHeld extends LiveBlogAutoEventBase {
	entryType: BundesligaShotSavedTypes;

	detail: {
		shooter: LiveBlogPerson;
		goalkeeper: LiveBlogPerson;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon
		 */
		text?: string;
	};
}

export interface LiveBlogEntryEditorialFreetext extends LiveBlogAutoEventBase {
	entryType: 'freetext';

	detail: {
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon
		 */
		headline?: string;
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon
		 */
		text: string;
	};
}

/**
 *
 */
export interface LiveBlogEntryEditorialImage extends LiveBlogAutoEventBase {
	entryType: LiveBlogEntryEditorialImageEnum;

	detail: {
		url: string;
		copyright: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon. Also not needed?!
		 */
		text?: string;
	};
}

/**
 *
 */
export interface LiveBlogEntryEditorialVideo extends LiveBlogAutoEventBase {
	entryType: 'video';

	detail: {
		videoId: string;
		duration?: number;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon. Also not needed?!
		 */
		text?: string;
	};
}

export type LiveBlogEntryStats =
	| LiveBlogEntryPlayerStats
	| LiveBlogEntryClubMetricProgressionStats
	| LiveBlogEntryBarStats
	| LiveBlogEntryPlayerRankingStats
	| LiveBlogEntryClubProgressRankingStats
	| LiveBlogEntryPlayerProgressRankingStats
	| LiveBlogEntryPieStats
	| LiveBlogEntryPressureHandling
	| LiveBlogEntryKeeperEfficiency
	| LiveBlogEntryBallRecoveryTime
	| LiveBlogEntryStackedColumnStats;

/**
 *
 */
export interface LiveBlogEntryPressureHandling extends LiveBlogAutoEventBase {
	entryType: 'stats';
	detail: {
		type: 'pressureHandling';
		title: string;
		person: LiveBlogPerson;
		escapeRate: number;
		pressureCount: number;
		matchFact?: boolean;
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		text?: string;
	};
}

/**
 *
 */
export interface LiveBlogEntryBallRecoveryTime extends LiveBlogAutoEventBase {
	entryType: 'stats';
	detail: {
		type: 'ballRecoveryTime';
		title: string;
		times: {
			label: LivetickerSides | string;
			value: number;
		}[];
		matchFact?: boolean;
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		text?: string;
	};
}

/**
 *
 */
export interface LiveBlogEntryKeeperEfficiency extends LiveBlogAutoEventBase {
	entryType: 'stats';
	detail: {
		type: 'keeperEfficiency';
		title: string;
		person: LiveBlogPerson;
		savedShots: number;
		xSaves: number;
		matchFact?: boolean;
		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		headline?: string;

		/**
		 * @deprecated Should be moved to LiveBlogEntryCommon?
		 */
		text?: string;
	};
}

export interface LiveBlogEntryPlayerRankingStats extends LiveBlogAutoEventBase {
	entryType: 'stats';

	detail: {
		headline?: string;
		text?: string;
		type: 'playerRanking';
		title: string;
		ranking: {
			rank: number;
			value: number;
			unit?: string;
			person: LiveBlogPerson;
			side: LivetickerSides;
		}[];
		matchFact: boolean;
	};
}

export interface LiveBlogEntryClubMetricProgressionStats extends LiveBlogAutoEventBase {
	entryType: 'stats';

	detail: {
		headline?: string;
		text?: string;
		type: 'clubMetricProgression';
		title: string;
		progression: {
			value: number;
			unit: string;
			playtime?: Playtime;
			matchSection?: BundesligaWhistleTypes;
		}[];
		matchFact: boolean;
	};
}

export interface LiveBlogEntryClubProgressRankingStats extends LiveBlogAutoEventBase {
	entryType: 'stats';

	detail: {
		headline?: string;
		text?: string;
		type: 'clubProgressRanking';
		metric?: string;
		title: string;
		home: {
			value: number;
			relativeValue: number;
		};
		away: {
			value: number;
			relativeValue: number;
		};
		matchFact: boolean;
	};
}

export interface LiveBlogEntryPlayerProgressRankingStats extends LiveBlogAutoEventBase {
	entryType: 'stats';

	detail: {
		headline?: string;
		text?: string;
		type: 'playerProgressRanking';
		ranking: {
			rank: number;
			value: number;
			person: LiveBlogPerson;
			side: LivetickerSides;
		}[];
		matchFact: boolean;
	};
}

export interface LiveBlogEntryPlayerStats extends LiveBlogAutoEventBase {
	entryType: 'stats';

	detail: {
		headline?: string;
		text?: string;
		type: 'player';
		title?: string;
		away: {
			person?: LiveBlogPerson;
			value: number;
		};
		home: {
			person?: LiveBlogPerson;
			value: number;
		};
		matchFact: boolean;
	};
}
export interface LiveBlogEntryBarStats extends LiveBlogAutoEventBase {
	entryType: 'stats';

	detail: {
		headline?: string;
		text?: string;
		type: 'bar';
		title: string;
		home: {
			value: number;
		};
		away: {
			value: number;
		};
		matchFact: boolean;
	};
}

export interface LiveBlogEntryPieStats extends LiveBlogAutoEventBase {
	entryType: 'stats';

	detail: {
		headline?: string;
		text?: string;
		type: 'pie';
		title: string;
		home: {
			value: number;
		};
		away: {
			value: number;
		};
		matchFact: boolean;
	};
}

export interface LiveBlogEntryStackedColumnStats extends LiveBlogAutoEventBase {
	entryType: 'stats';

	detail: {
		headline?: string;
		text?: string;
		type: 'stackedcolumn';
		title: string;
		home: {
			primaryLabel: string;
			secondaryLabel: string;
			primaryValue: number;
			secondaryValue: number;
		};
		away: {
			primaryLabel: string;
			secondaryLabel: string;
			primaryValue: number;
			secondaryValue: number;
		};
		matchFact: boolean;
	};
}

/**
 * @todo/dfl/schema should be merged with BundesligaEmbed
 */
export type LiveBlogEntryEditorialEmbed = LiveBlogEntryEditorialEmbedSocials | LiveBlogEntryEditorialEmbedMonterosa;
export interface LiveBlogEntryEditorialEmbedSocials extends LiveBlogAutoEventBase {
	entryType: 'embed';
	detail: BundesligaEmbedSocials;
}
export interface LiveBlogEntryEditorialEmbedMonterosa extends LiveBlogAutoEventBase {
	entryType: 'embed';
	detail: BundesligaEmbedMonterosa & {
		headline?: string;
		text?: string;
	};
}

export interface MatchdayRange {
	start: string;
	end: string;
}

export interface LiveBlogInfos {
	name?: string;
	isTyping: boolean;
	homeIsTyping: boolean;
	awayIsTyping: boolean;
}

export interface LiveBlogEntries {
	[key: string]: LiveBlogAutoEvents;
}

/**
 * @todo/dfl combine with match.MatchClub
 */
export interface LiveBlogClub {
	dflDatalibraryClubId: string;
	boxOfficeUrl?: string;
	name?: string;
	nameShort?: string;
	nameFull: string;
	threeLetterCode: string;
	gradientStartColor: string;
	gradientEndColor: string;
	textColor: string;
	logoUrl: string;
}

/**
 * Basically the same interface as common/playtime, but injuryTime --> extraTime.
 * So injuryTime is used for the wrapper while extraTime is used in the autoEvent entry.
 *
 * @todo/dfl Both models should be merged and schemas need to be adjusted !!
 */
export interface LiveBlogMinuteOfPlay {
	minute: number;
	injuryTime?: number;
}

interface LiveBlogScore {
	live: number;
	halftime?: number;
}

export interface LiveBlogClubs {
	home: LiveBlogClub;
	away: LiveBlogClub;
}

/**
 *
 */
export type LiveBlogAutoEvents =
	| LiveBlogAutoEventGoal
	| LiveBlogAutoEventPenalty
	| LiveBlogAutoEventWhistle
	| LiveBlogAutoEventCard
	| LiveBlogAutoEventVar
	| LiveBlogAutoEventSubstitution
	| LiveBlogAutoEventLineup
	| LiveBlogEntryEditorialFreetext
	| LiveBlogAutoEventShotHeld
	| LiveBlogEntryEditorialEmbed
	| LiveBlogEntryEditorialImage
	| LiveBlogEntryEditorialVideo
	| LiveBlogEntryStats
	| LiveBlogEntryPlayerRecord;

export interface LiveBlogScores {
	home: LiveBlogScore;
	away: LiveBlogScore;
}

/**
 * Model for displaying a liveticker including all matchstats, autoevents from sts and editorial
 */
export interface LiveBlogMatch {
	matchdayRange: MatchdayRange;
	dateQuality?: MatchDateQuality;
	matchStatus: BundesligaMatchStates;
	dflDatalibraryMatchId: string;
	matchId: string;
	matchdayId?: string; // deprecated 2023-10-12
	dflDatalibraryCompetitionId: string;
	dflDatalibrarySeasonId: string;
	dflDatalibraryMatchdayId: string;
	matchday: number;
	matchdayLabel?: string; // deprecated 2023-10-12
	competitionName?: string;
	seasonOrder: number;
	score: LiveBlogScores;
	slugs: {
		slugShort?: string; // deprecated 2023-10-12
		slugLong: string;
	};
	teams: LiveBlogClubs;
	highlight?: {
		video?: MatchHighlightVideo;
	};
	liveBlogInfos?: LiveBlogInfos;
	liveBlogEntries?: LiveBlogEntries;
	liveBlogUrl?: string; // deprecated 2023-10-12
	stadiumName?: string; // deprecated 2023-10-12
	plannedKickOff?: string;
	kickOff?: string;
	minuteOfPlay?: LiveBlogMinuteOfPlay;
	referee?: {
		displayName?: string;
	};
	stadiumIconUrlBlack?: string; // deprecated 2023-10-12
	stadiumIconUrlWhite?: string; // deprecated 2023-10-12
}

export interface LiveBlogCustomMatch extends LiveBlogMatch {
	matchName?: string;
	stadiumName?: string;
	matchdayLabel?: string;
	hideHeader?: boolean;
}

/**
 * Model for player basic events
 */
export interface PlayerBasicEvent {
	sub: {
		in?: boolean;
		out?: boolean;
	};
	goals: {
		ownGoals?: number;
		goals?: number;
	};

	cards: {
		yellow?: boolean;
		red?: boolean;
		yellowRed?: boolean;
	};
	personId: any;
	playtime?: any;
	svgImageOffsetsX?: any;
}

/**
 * Model for player basic event list
 */
export interface PlayerBasicEventList {
	home: {};
	away: {};
}
